import React from 'react';
import { Link } from 'gatsby';
import FooterWaves from './footer-waves';

function CtaFooter() {
  return (
    <>
      <FooterWaves />

      {/* CTA block */}
      <section className="container mx-auto text-center py-6 mb-12">
        <h2 className="text-3xl md:text-5xl text-center text-white">
          Jetzt handeln
        </h2>
        <div className="w-full mb-4">
          <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
        </div>

        <h3 className="text-xl md:text-2xl">
          Holen Sie sich Ihren Umsatz zurück!
        </h3>

        <Link to="/bestellformular/">
          <button
            type="submit"
            className="mx-auto lg:mx-0 hover:shadow-md bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg"
          >
            Zum Bestellformular
          </button>
        </Link>
        <p>
          Sie haben Fragen?{' '}
          Besuchen Sie unsere <a href="/faq" className="underline">FAQ</a> oder
          <a href="/kontakt" className=" ml-2 underline">
            kontaktieren Sie uns.
          </a>
        </p>
      </section>
    </>
  );
}

export default CtaFooter;
